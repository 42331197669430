import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';

const VideoPlaybackContext = createContext();

export const useVideoPlayback = () => useContext(VideoPlaybackContext);

const VideoPlaybackProvider = ({ children }) => {
  const [isVideoEnded, setIsVideoEnded] = useState(false);

  const handleVideoEnded = () => {
    setIsVideoEnded(true);
  };

  return (
    <VideoPlaybackContext.Provider value={{ isVideoEnded, handleVideoEnded }}>
      {children}
    </VideoPlaybackContext.Provider>
  );
};

VideoPlaybackProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default VideoPlaybackProvider;
