/* eslint-disable import/no-duplicates */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-underscore-dangle */
import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { MdRemoveShoppingCart } from 'react-icons/md';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { MdOutlineSell } from 'react-icons/md';
import { RiHome2Line } from 'react-icons/ri';
import { CiShop } from 'react-icons/ci';
import { PiShoppingCartSimple } from 'react-icons/pi';
import { BsBuildingCheck } from 'react-icons/bs';
import NavB from '../../Nav/NavB';
import hero from '../assets/hero.png';
import styles from './css/PalmStore.module.scss';
import Success from '../components/Success';
import Loader from '../../PalmSchool/components/Loader';
import Sell from './sell/Sell';
import footer from '../components/css/Footer.module.scss';
import Underdev from '../components/Underdev';

const animateBook = {
  isHidden: { opacity: 0, x: '-100%' },
  isVisible: (transit) => ({
    opacity: 1,
    x: 0,
    transition: { delay: transit },
  }),
};

const navs = [
  { name: 'Home', link: '/', icon: <RiHome2Line className={footer.icon} /> },
  { name: 'Sell', link: '#', icon: <MdOutlineSell className={footer.icon} /> },
  { name: 'Market', link: '#', icon: <CiShop className={footer.icon} /> },
  { name: 'Cart', link: '#', icon: <PiShoppingCartSimple className={footer.icon} /> },
  { name: 'Orders', link: '#', icon: <BsBuildingCheck className={footer.icon} /> },
];

const validationSchema = Yup.object().shape({
  product: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  phone: Yup.string().required('Required'),
});

const PalmStore = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [selected, setSelected] = useState('Market');
  const [delay, setDelay] = useState(0.1);

  useEffect(() => {
    const sections = document.querySelectorAll('.nav');
    const options = {
      threshold: 0.3,
    };

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setSelected(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);
    sections.forEach((section) => observer.observe(section));
  }, []);

  const popupRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setShowPopup(true);
    }, 4000);
  }, []);

  const handlePopupClick = () => {
    if (showPopup) {
      setShowPopup(false);
    } else if (isOpenForm) {
      setIsOpenForm(false);
    }
  };

  const handleForm = () => {
    setIsOpenForm(!isOpenForm);
    if (success) {
      setIsOpenForm(false);
    }
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const formik = useFormik({
    initialValues: {
      product: '',
      description: '',
      phone: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      const data = await fetch(`${process.env.REACT_APP_BASEURL}/request/new`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(values),
      });
      const response = await data.json();
      if (response) {
        setIsLoading(false);
        setIsOpenForm(false);
        setSuccess(true);
        formik.resetForm();
      }
    },
  });

  return (
    <>
      <NavB palmstore />
      {selected === 'Market' && (
      <motion.section
        initial="isHidden"
        animate="isVisible"
        variants={animateBook}
        className={styles.container}
      >
        <div className={styles.hero}>
          <img src={hero} alt="hero" className={styles.img} />
          <p className={styles.heroText}>Buy Fresh from PALMSTORE</p>
        </div>
        <div className={styles.products}>
          <MdRemoveShoppingCart className={styles.cart} />
          <p className={styles.cartText}>Stay tuned for product updates or send a request!</p>
          <p className={styles.cta} onClick={handleForm}>Make a Request</p>
        </div>
        {showPopup && (
          <div className={styles.request} ref={popupRef} onClick={handlePopupClick}>
            <button type="button" className={styles.requestBtn} onClick={handleForm}>
              Can&apos;t find what you need?
              Tap to make a request!
            </button>
          </div>
        )}
        {(isOpenForm) && (
          <div className={styles.request} ref={popupRef} onClick={handlePopupClick}>
            <form className={styles.requestForm} onSubmit={formik.handleSubmit} onClick={(event) => stopPropagation(event)}>
              <h2 className={styles.formTitle}>Request Form</h2>
              <div className={styles.formGroup}>
                <label htmlFor="product" className={styles.label}>Product name</label>
                <input
                  type="text"
                  name="product"
                  onChange={formik.handleChange}
                  value={formik.values.farmSize}
                />
                {formik.errors.product && formik.touched.product && <div className={styles.error}>{formik.errors.product}</div>}
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="description" className={styles.label}>Brief description</label>
                <input
                  type="text"
                  name="description"
                  onChange={formik.handleChange}
                  value={formik.values.description}
                />
                {formik.errors.description && formik.touched.description && <div className={styles.error}>{formik.errors.description}</div>}
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="phone" className={styles.label}>Phone number</label>
                <input
                  type="text"
                  name="phone"
                  onChange={formik.handleChange}
                  value={formik.values.phone}
                />
                {formik.errors.phone && formik.touched.phone && <div className={styles.error}>{formik.errors.phone}</div>}
              </div>
              <button type="submit" className={styles.submitBtn}>Send</button>
            </form>
          </div>
        )}
        {success && <Success />}
        {isLoading && <Loader />}
      </motion.section>
      )}
      {selected === 'Sell' && <Sell />}
      {selected === 'Cart' && <Underdev />}
      {selected === 'Orders' && <Underdev />}
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          duration: 0.5, type: 'spring', stiffness: 100, delay: 0.5,
        }}
        className={footer.wrapper}
      >
        {navs.map((nav) => (
          <Link
            to={nav.link}
            key={nav.name}
            id={nav.name}
            onClick={() => {
              setSelected(nav.name);
            }}
            className={`${footer.nav} ${selected === nav.name ? footer.active : ''}`}
            initial={{ x: -70 }}
            animate={{ x: 0 }}
            transition={{ duration: 0.5, delay: () => setDelay(delay + 0.2) }}
          >
            {nav.icon}
            <p className={footer.name}>{nav.name}</p>
          </Link>
        ))}
      </motion.div>
    </>
  );
};

export default PalmStore;
