/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { MdOutlinePanTool } from 'react-icons/md';
import styles from './css/Success.module.scss';

const Underdev = () => {
  const [closeModal, setCloseModal] = useState(false);

  const handleCloseModal = () => {
    setCloseModal(true);
  };
  return (
    <div className={`${styles.container} ${closeModal && styles.hide}`} onClick={handleCloseModal}>
      <article className={styles.article}>
        <MdOutlinePanTool className={styles.icon} />
        <h2 className={styles.heading}>Under Development</h2>
        <p className={styles.text}>This feature is currently in-progress.</p>
      </article>
    </div>
  );
};

export default Underdev;
