import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const token = localStorage.getItem('token');

export const addHarvestStock = createAsyncThunk(
  'harvest/addStock',
  async (formDataArray, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL}/harvest/new`,
        formDataArray,
        config,
      );

      toast.success('Product added successfully');
      return data;
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 400) {
        toast.error(error.response.data.error);
      } else {
        toast.error('Check your internet connection and try again');
      }
      return rejectWithValue(error.response.data.error);
    }
  },
);

export const updateHarvestStock = createAsyncThunk(
  'harvest/updateStock',
  async (updateData, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_BASEURL}/harvest/stock/${updateData.id}`,
        updateData.update,
        config,
      );

      toast.success('Stock was updated successfully.');
      return data;
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(error.response.data.error);
      }
      if (error.response && error.message) {
        toast.error(error.response.data.error);
        return rejectWithValue(error.response);
      }
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  },
);

export const getHarvestStock = createAsyncThunk('harvest/getStock', async (_, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get(`${process.env.REACT_APP_BASEURL}/harvest/stock`, config);

    return data;
  } catch (error) {
    if (error.response.status === 400) {
      toast.error(error.response.data.error);
    }
    if (error.response && error.message) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response);
    }
    toast.error(error.response.data.error);
    return rejectWithValue(error.response.data.error);
  }
});

export const deleteHarvestStock = createAsyncThunk(
  'harvest/deleteStock',
  async (id, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.delete(
        `${process.env.REACT_APP_BASEURL}/harvest/stock/${id}`,
        config,
      );

      toast.success('Product was successfully removed.');
      return data;
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(error.response.data.error);
      }
      if (error.response && error.message) {
        toast.error(error.response.data.error);
        return rejectWithValue(error.response);
      }
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  },
);
