/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { LuSendHorizonal } from 'react-icons/lu';
import styles from './Feedback.module.scss';

const Feedback = ({ onSubmit }) => {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');

  const handleRating = (rate) => {
    setRating(rate);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (rating === 0) {
      toast.error('Please select a rating');
      return;
    }
    onSubmit({ rating, comment });
    setRating(0);
    setComment('');
  };

  return (
    <form onSubmit={handleSubmit} className={styles.feedback}>
      <h4 className={styles.subtitle}>Please rate the course</h4>
      <div className={styles.rating}>
        {[1, 2, 3, 4, 5].map((star) => (
          <span
            key={star}
            className={star <= rating ? styles.filledStar : styles.emptyStar}
            onClick={() => handleRating(star)}
          >
            ★
          </span>
        ))}
      </div>
      <div className={styles.comment}>
        <textarea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Kindly send us a feedback"
          rows="3"
          className={styles.textarea}
        />
        <button type="submit" className={styles.submitBtn}>
          <LuSendHorizonal className={styles.icon} />
        </button>
      </div>
    </form>
  );
};

Feedback.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default Feedback;
