import { createSlice } from '@reduxjs/toolkit';
import {
  addHarvestStock, deleteHarvestStock, updateHarvestStock, getHarvestStock,
} from '../reducer/harvestAction';

const initialState = {
  loading: false,
  harvested: {},
  error: null,
  success: false,
};

const harvestSlice = createSlice({
  name: 'harvest',
  initialState,
  reducers: {},
  extraReducers: {
    [addHarvestStock.pending]: (state) => {
      state.loading = true;
    },
    [addHarvestStock.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.harvested = payload;
      state.success = true;
    },
    [addHarvestStock.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      // console.log(payload);
    },

    // Update Farm Stock
    [updateHarvestStock.pending]: (state) => {
      state.loading = true;
    },
    [updateHarvestStock.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.harvested = payload;
      state.success = true;
    },
    [updateHarvestStock.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      console.log(payload);
    },

    // Delete Farm Stock
    [deleteHarvestStock.pending]: (state) => {
      state.loading = true;
    },
    [deleteHarvestStock.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.harvested = payload;
      state.success = true;
    },
    [deleteHarvestStock.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      console.log(payload);
    },

    // Get Farm Stock
    [getHarvestStock.pending]: (state) => {
      state.loading = true;
    },
    [getHarvestStock.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.harvested = payload;
      state.success = true;
    },
    [getHarvestStock.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      console.log(payload);
    },
  },
});

export default harvestSlice.reducer;
