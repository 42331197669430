/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Farmn from './Farm';
import Harvest from './Harvest';
import styles from './css/RenderForm.module.scss';

const RenderForm = ({ stock }) => {
  const [isOnFarm, setIsOnFarm] = useState(true);
  const [isHarvested, setIsHarvested] = useState(false);

  const handleFarm = () => {
    setIsOnFarm(true);
    setIsHarvested(false);
  };

  const handleHarvest = () => {
    setIsOnFarm(false);
    setIsHarvested(true);
  };

  return (
    <section className={`${styles.formDiv} ${stock && styles.myStock}`}>
      <div className={styles.flipper}>
        <article className={styles.option}>
          <input type="radio" id="onFarm" name="option" checked={isOnFarm} onChange={handleFarm} />
          <label htmlFor="onFarm">On Farm</label>
        </article>
        <article className={styles.option}>
          <input type="radio" id="harvested" name="option" checked={isHarvested} onChange={handleHarvest} />
          <label htmlFor="harvested">Harvested</label>
        </article>
      </div>
      {isOnFarm && <Farmn />}
      {isHarvested && <Harvest />}
    </section>
  );
};

RenderForm.defaultProps = {
  stock: false,
};

RenderForm.propTypes = {
  stock: PropTypes.bool,
};

export default RenderForm;
