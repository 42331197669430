import React from 'react';
import styles from './Assessment.module.scss';
import Feedback from './FeedBack';

const handleFeedbackSubmit = (feedback) => {
  console.log('Feedback submitted:', feedback);
};

const BackToCourse = () => (
  <section className={styles.back}>
    <p className={styles.title}>You have reached the end of your assessment</p>
    <Feedback onSubmit={handleFeedbackSubmit} />
    <button
      type="button"
      className={styles.btn}
    >
      Back to Course
    </button>
  </section>
);

export default BackToCourse;
