/* eslint-disable max-len */
/* eslint-disable arrow-body-style */
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MdOutlineMoodBad } from 'react-icons/md';
import { motion } from 'framer-motion';
import NavB from './Nav/NavB';
import styles from './Error.module.scss';

const Error = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  return (
    <>
      <NavB />
      <motion.div
        className={styles.container}
        initial={{ opacity: 0, x: '-100%' }}
        whileInView={{ opacity: 1, x: 0 }}
      >
        <MdOutlineMoodBad className={styles.icon} />
        <h1 className={styles.title}>{isAuthenticated ? 'Coming soon!' : 'No access yet!'}</h1>
        {!isAuthenticated && <p className={styles.subTitle}>You must register and sign in to have access.</p>}
        {isAuthenticated && <p className={styles.subTitle}>You will be the first to know when this feature is ready.</p>}
        <Link to="/" className={styles.link}>
          Back to Home
        </Link>
      </motion.div>
    </>
  );
};

export default Error;
