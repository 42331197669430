/* eslint-disable arrow-body-style */
/* eslint-disable no-undef */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
// import { useParams } from 'react-router-dom';
import { MdNavigateNext } from 'react-icons/md';
import PropTypes from 'prop-types';
// import { toast } from 'react-toastify';
import NavB from '../../../Nav/NavB';
import styles from './Assessment.module.scss';
// import Feedback from './FeedBack';
import correct from '../../images/correct.svg';
import wrong from '../../images/wrong.svg';

const Assessment = ({ module }) => {
  const selectedModule = module;
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(selectedModule.assessments.questions[0]);
  const [selectedAnswer, setSelectedAnswer] = useState('');
  const [correctAnswer, setCorrectAnswer] = useState(false);
  const [wrongAnswer, setWrongAnswer] = useState(false);

  const handleSubmission = () => {
    if (selectedAnswer === selectedModule.assessments.answers[currentQuestionIndex]) {
      setCorrectAnswer(true);
    } else {
      setWrongAnswer(true);
    }
  };

  const handleNext = () => {
    if (currentQuestionIndex < selectedModule.assessments.questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setCorrectAnswer(false);
      setWrongAnswer(false);
    } else {
      // navigate(`/courses/${courseId}/next`);
      console.log('End of assessment');
    }
  };

  return (
    <>
      <NavB palmschool />
      <div className={styles.container}>
        <h1 className={styles.title}>{module.subtopic}</h1>
        <section className={styles.flexContent}>
          <article className={styles.subheading}>
            <h3 className={styles.subtitle}>Assessment</h3>
          </article>
          <form className={styles.form}>
            <div className={styles.groupDiv}>
              <article className={styles.question}>
                <p className={styles.text}>{`Q${currentQuestionIndex + 1}:`}</p>
                <p className={styles.text}>
                  {selectedModule.assessments.questions[currentQuestionIndex]}
                </p>
              </article>
              <article className={styles.answer}>
                {selectedModule.assessments.options[currentQuestionIndex].map((option, index) => (
                  <div key={index} className={styles.option}>
                    <input
                      type="radio"
                      id={`option-${index}`}
                      name="answer"
                      onChange={(e) => setSelectedAnswer(e.target.value)}
                      value={option}
                      className={styles.input}
                    />
                    <label htmlFor={`option-${index}`}>{option}</label>
                  </div>
                ))}
              </article>
            </div>
            <button
              type="button"
              className={styles.btn}
              onClick={handleSubmission}
            >
              Submit
            </button>
          </form>
          {correctAnswer && (
            <article className={styles.correct}>
              <div className={styles.wrapper}>
                <img src={correct} alt="Correct answer" className={styles.icon} />
                <p className={styles.text}>
                  Correct!
                  <br />
                  You got it right
                </p>
                <button type="button" className={styles.link} onClick={handleNext}>
                  Next
                  <MdNavigateNext className={styles.icon} />
                </button>
              </div>
            </article>
          )}
          {wrongAnswer && (
            <article className={styles.correct}>
              <div className={styles.wrapperB}>
                <img src={wrong} alt="Wrong answer" className={styles.icon} />
                <p className={styles.text}>Sorry, You got it wrong</p>
                <p className={styles.text}>CORRECT ANSWER</p>
                <div className={styles.correctAnswer}>
                  {selectedModule.assessments.options[currentQuestionIndex].map((option, index) => (
                    <div key={index} className={styles.option}>
                      <input
                        type="radio"
                        name="correct-answer"
                        checked={option === selectedModule.assessments.answers[currentQuestionIndex]}
                        value={option}
                        className={styles.input}
                        readOnly
                      />
                      <label htmlFor={`correct-answer-${index}`}>{option}</label>
                    </div>
                  ))}
                </div>
                <button type="button" className={styles.link} onClick={handleNext}>
                  Next
                  <MdNavigateNext className={styles.icon} />
                </button>
              </div>
            </article>
          )}
        </section>
      </div>
    </>
  );
};

Assessment.propTypes = {
  module: PropTypes.object.isRequired,
};

export default Assessment;

// {feedBack && <Feedback />}
