/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
// /* eslint-disable arrow-body-style */
// /* eslint-disable import/no-extraneous-dependencies */
// /* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ImSpinner3 } from 'react-icons/im';
import NavB from '../../../Nav/NavB';
import VideoPlayer from '../../components/VideoPlayer';
import Assessment from '../Assessment/Assessment';
// import { useVideoPlayback } from '../../components/context/VideoContext';
import styles from './css/Course.module.scss';
import Loader from '../../components/Loader';

const NextModule = () => {
  const { moduleId } = useParams();
  const { courseId } = useParams();
  const navigate = useNavigate();

  // const { isVideoEnded } = useVideoPlayback();
  // const [selectedCourse, setSelectedCourse] = useState(null);
  const [modules, setModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState({});
  const [isError, setIsError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showAssessment, setShowAssessment] = useState(false);

  useEffect(() => {
    const fetchModule = async () => {
      const token = localStorage.getItem('token');
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      try {
        setIsLoading(true);
        if (!moduleId) {
          setIsError('Module not found');
          setIsLoading(false);
          return;
        }
        const response = await fetch(`${process.env.REACT_APP_BASEURL}/courses/${courseId}`, {
          method: 'GET',
          headers,
        });

        if (!response.ok) {
          setIsError('Failed to fetch course');
          toast.error('Check your internet connection and try again');
          setIsLoading(false);
          return;
        }

        const data = await response.json();
        // setSelectedCourse(data);
        setModules(data.modules);

        const module = await data.modules.find((mod) => mod._id === moduleId);
        setSelectedModule(module);

        setIsLoading(false);
      } catch (error) {
        setIsError(error.message);
        toast.error('This module seems to be missing');
      } finally {
        setIsLoading(false);
      }
    };

    fetchModule();
  }, [courseId, moduleId]);

  if (isError) {
    return (
      <div>
        Error:
        {isError}
      </div>
    );
  }

  console.log(modules);

  const currentModuleIndex = modules.findIndex((mod) => mod._id === moduleId);
  const nextModule = modules[currentModuleIndex + 1];

  const handleAssessment = () => {
    // navigate(`/palmschool/courses/${courseId}/${moduleId}/assessment`);
    setShowAssessment(true);
  };

  const handleNextClick = () => {
    if (nextModule) {
      navigate(`/palmschool/courses/${courseId}/${nextModule._id}`);
    } else {
      toast.info('This is the last module.');
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader loading={isLoading} />
      ) : (
        <>
          <NavB palmschool />
          <section className={styles.container}>
            <div className={styles.div}>
              <h1 className={styles.title}>{selectedModule.subtopic}</h1>
            </div>
            <VideoPlayer videoSrc={selectedModule.link} />
            <div className={styles.actionDiv}>
              <button
                type="button"
                className={`${styles.exit} ${styles.btn}`}
                onClick={handleNextClick}
              >
                {isLoading ? <ImSpinner3 className={styles.icon} /> : 'Next'}
              </button>
              <button
                type="button"
                className={`${styles.continue} ${styles.btn}`}
                onClick={handleAssessment}
              >
                Take Assessment
              </button>
            </div>
            {isLoading && <Loader loading={isLoading} />}
          </section>
          {
        showAssessment && (
          <Assessment module={selectedModule} />
        )
      }
        </>
      )}
    </>
  );
};

export default NextModule;
