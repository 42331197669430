/* eslint-disable import/no-extraneous-dependencies */
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import authReducer from './features/authSlice';
import farmReducer from './features/farmSlice';
import harvestedReducer from './features/harvestSlice';
import notifReducer from './features/notifSlice';

const token = localStorage.getItem('token');
const authToken = localStorage.getItem('authToken');
const notifications = localStorage.getItem('notifications');

const initialState = {
  auth: {
    loading: false,
    isAuthenticated: authToken || false,
    user: {},
    token: token || null,
    error: null,
    success: false,
    phone: '',
    verified: false,
  },
  farm: {
    loading: false,
    farms: {},
    error: null,
    success: false,
  },
  harvest: {
    loading: false,
    harvested: {},
    error: null,
    success: false,
  },
  notif: {
    loading: false,
    notifications: notifications || [],
    error: null,
  },
};

const rootReducer = combineReducers({
  auth: authReducer,
  farm: farmReducer,
  harvest: harvestedReducer,
  notif: notifReducer,
  preloadedState: initialState,
});

export default configureStore({
  reducer: rootReducer,
});
