import React, { useState } from 'react';
import { motion } from 'framer-motion';
import sell from '../../assets/sell_img.png';
import Crop from './Crop';
import Equipment from './Equipment';
import Farm from './Farm';
import styles from './Sell.module.scss';

const animateBook = {
  isHidden: { opacity: 0, x: '-100%' },
  isVisible: (transit) => ({
    opacity: 1,
    x: 0,
    transition: { delay: transit },
  }),
};

const Sell = () => {
  const [isCrop, setIsCrop] = useState(true);
  const [isEquipment, setIsEquipment] = useState(false);
  const [isFarm, setIsFarm] = useState(false);

  const handleSell = (type) => {
    switch (type) {
      case 'crop':
        setIsCrop(true);
        setIsEquipment(false);
        setIsFarm(false);
        break;
      case 'equipment':
        setIsCrop(false);
        setIsEquipment(true);
        setIsFarm(false);
        break;
      case 'farm':
        setIsCrop(false);
        setIsEquipment(false);
        setIsFarm(true);
        break;
      default:
        setIsCrop(true);
        setIsEquipment(false);
        setIsFarm(false);
    }
  };

  return (
    <motion.section
      initial="isHidden"
      animate="isVisible"
      variants={animateBook}
      className={styles.container}
    >
      <div className={styles.hero}>
        <img src={sell} alt="hero" className={styles.img} />
        <p className={styles.heroText}>Sell & Lease Faster on PALMSTORE</p>
      </div>
      <div className={styles.products}>
        <div className={styles.productType}>
          <button
            type="button"
            className={`${styles.productButton} ${isCrop ? styles.active : ''}`}
            onClick={() => handleSell('crop')}
          >
            Crops
          </button>
          <button
            type="button"
            className={`${styles.productButton} ${isEquipment ? styles.active : ''}`}
            onClick={() => handleSell('equipment')}
          >
            Equipment
          </button>
          <button
            type="button"
            className={`${styles.productButton} ${isFarm ? styles.active : ''}`}
            onClick={() => handleSell('farm')}
          >
            Farm
          </button>
        </div>
        <div className={styles.productForm}>
          {isCrop && <Crop />}
          {isEquipment && <Equipment />}
          {isFarm && <Farm />}
        </div>
      </div>
    </motion.section>
  );
};

export default Sell;
