/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {
  Link, Outlet, useNavigate, useLocation,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { FaRegUserCircle } from 'react-icons/fa';
import { MdKeyboardBackspace, MdOutlineShoppingCart } from 'react-icons/md';
import { IoIosNotificationsOutline } from 'react-icons/io';
import { CiSearch } from 'react-icons/ci';
import styles from './Nav.module.scss';
import palmshops from '../PalmSchool/images/logo/logo_text.svg';
import { signOut } from '../redux/reducer/authActions';
import Loader from '../Home/Loader';
/* eslint-disable react/jsx-boolean-value */

// eslint-disable-next-line react/prop-types
const NavB = ({ palmschool, palmtrack, palmstore }) => {
  useEffect(() => {
    AOS.init({
      duration: 400,
      easing: 'ease-in',
      once: true,
    });
  }, []);

  const notif = useSelector((state) => state.notif.notifications);

  const navigate = useNavigate();
  const location = useLocation();

  const navigateTo = (path) => navigate(path);
  const dispatch = useDispatch();
  const { loading, success } = useSelector((state) => state.auth);
  const [isShowing, setIsShowing] = useState(false);

  const handleBackClick = () => {
    if (location.pathname === '/palmSchool' || location.pathname === '/palmStore' || location.pathname === '/palmTrack' || location.pathname === '/palmVest') {
      navigate('/');
    } else {
      navigate(-1);
    }
  };

  const showMenu = () => {
    setIsShowing(!isShowing);
  };

  const handleSignOut = () => {
    dispatch(signOut());
    if (success) {
      navigate('/');
    }
  };

  return (
    <header className={styles.scroller}>
      <nav className={styles.navBar}>
        <div className={styles.navPre}>
          <MdKeyboardBackspace className={styles.mobileMenuIcon} onClick={handleBackClick} />

          <Link to="/" className={styles.navLink}>
            <img
              src={palmshops}
              alt="PalmShops.com logo"
              className={styles.navLogo}
            />
          </Link>
        </div>
        <div className={styles.profile}>
          {palmstore && <MdOutlineShoppingCart className={styles.icon} />}
          {!palmtrack && <IoIosNotificationsOutline className={styles.icon} />}
          {(!notif && notif.map((no) => !no.isRead)) && <span className={styles.notif} />}
          {palmschool && (
          <>
            <CiSearch className={styles.icon} />
            <Link to="#" className={styles.profileImg} onClick={showMenu}>
              <FaRegUserCircle className={styles.user} />
            </Link>
            { isShowing && (
              <article className={styles.action}>
                <button type="button" onClick={handleSignOut} className={styles.logout}>
                  Log out
                </button>
              </article>
            )}
          </>
          )}
          {palmtrack && <IoIosNotificationsOutline className={styles.icon} onClick={() => navigateTo('/palmTrack/mystock')} />}
        </div>
        {loading && <Loader loading={loading} />}
      </nav>

      <Outlet />
    </header>
  );
};

NavB.defaultProps = {
  palmtrack: false,
  palmschool: false,
  palmstore: false,
};

NavB.propTypes = {
  palmtrack: PropTypes.bool,
  palmschool: PropTypes.bool,
  palmstore: PropTypes.bool,
};

export default NavB;
