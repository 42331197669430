import { createSlice } from '@reduxjs/toolkit';
import {
  addFarmStock, deleteFarmStock, updateFarmStock, getFarmStock,
} from '../reducer/farmAction';

const initialState = {
  loading: false,
  farms: {},
  error: null,
  success: false,
};

const farmSlice = createSlice({
  name: 'farm',
  initialState,
  reducers: {},
  extraReducers: {
    [addFarmStock.pending]: (state) => {
      state.loading = true;
    },
    [addFarmStock.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.farms = payload;
      state.success = true;
    },
    [addFarmStock.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    // Update Farm Stock
    [updateFarmStock.pending]: (state) => {
      state.loading = true;
    },
    [updateFarmStock.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.farms = payload;
      state.success = true;
    },
    [updateFarmStock.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      console.log(payload);
    },

    // Delete Farm Stock
    [deleteFarmStock.pending]: (state) => {
      state.loading = true;
    },
    [deleteFarmStock.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.farms = payload;
      state.success = true;
    },
    [deleteFarmStock.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      console.log(payload);
    },

    // Get Farm Stock
    [getFarmStock.pending]: (state) => {
      state.loading = true;
    },
    [getFarmStock.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.farms = payload;
      state.success = true;
    },
    [getFarmStock.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      console.log(payload);
    },
  },
});

export default farmSlice.reducer;
