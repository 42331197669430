/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaArrowRight } from 'react-icons/fa6';
import { MdKeyboardBackspace } from 'react-icons/md';
import 'aos/dist/aos.css';
import OtpInput from 'react-otp-input';
import { motion } from 'framer-motion';
import styles from './css/Confirm.module.scss';
import logo from '../images/logo/logo_img.svg';
import { verifyOTP, resendOTP } from '../../redux/reducer/authActions';
import Loader from '../components/Loader';

const Confirm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBackClick = () => {
    if (location.key !== 'default') {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  const formatPhoneNumber = (phone) => {
    const firstPart = phone.slice(0, 5);
    const lastPart = phone.slice(-2);
    const maskedPart = '*'.repeat(phone.length - 7);

    return `${firstPart}${maskedPart}${lastPart}`;
  };

  const {
    loading, verified, phone,
  } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const [otp, setOtp] = useState(['', '', '', '', '', '']);

  const handleOTPVerification = () => {
    const enteredOTP = otp.join('');
    dispatch(verifyOTP(enteredOTP));
  };

  const handleResendOTP = () => {
    dispatch(resendOTP());
    navigate('/confirm-phone');
  };

  useEffect(() => {
    if (verified) {
      navigate('/login');
    }
  }, [verified, navigate]);

  return (
    <motion.section
      className={styles.container}
      initial={{ opacity: 0, x: '-100%' }}
      whileInView={{ opacity: 1, x: 0 }}
    >
      <MdKeyboardBackspace className={styles.angle} onClick={handleBackClick} />
      <Link to="/" className={styles.imgCent}>
        <img src={logo} alt="PalmShops.com logo" className={styles.logo} />
      </Link>
      <article className={styles.title}>
        <h1 className={styles.subtitle}>Phone Number Verification</h1>
        <p className={styles.desc}>
          {`An OTP has been sent to ${phone && formatPhoneNumber(phone)}. Kindly enter the code
          in the boxes below`}
        </p>
        <OtpInput
          value={otp.join('')}
          onChange={(value) => setOtp(value.split(''))}
          numInputs={6}
          renderSeparator={<span />}
          placeholder="******"
          containerStyle={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '2rem',
          }}
          inputStyle={{
            width: '3rem',
            height: '3rem',
            margin: '0 0.1rem',
            fontSize: '1.5rem',
            borderRadius: '4px',
            border: '1px solid #ced4da',
          }}
          isInputNum
          renderInput={(props, index) => <input {...props} key={index} />}
        />
        <button type="submit" onClick={handleOTPVerification} className={styles.btn} disabled={loading}>
          Verify
          <FaArrowRight />
        </button>
        <p className={styles.link}>
          Didn&apos;t get the code?
          <Link to="#" onClick={handleResendOTP}>Resend OTP</Link>
        </p>
      </article>
      {loading && <Loader loading={loading} />}
    </motion.section>
  );
};

export default Confirm;
