/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef } from 'react';
import '@vidstack/react/player/styles/base.css';
import PropTypes from 'prop-types';
import { Media, Video } from '@vidstack/player-react';
import { useVideoPlayback } from './context/VideoContext';
import styles from './css/VideoPlayer.module.scss';

const VideoPlayer = ({ videoSrc }) => {
  const videoRef = useRef(null);
  const { handleVideoEnded } = useVideoPlayback();

  return (
    <Media className={styles.videoContainer}>
      <Video
        ref={videoRef}
        loading="visible"
        controls
        onEnded={handleVideoEnded}
        preload="true"
        className={styles.videoDiv}
      >
        <video
          loading="visible"
          src={videoSrc}
          preload="none"
          data-video="0"
          className={styles.video}
          controls
        />
      </Video>
    </Media>
  );
};

VideoPlayer.defaultProps = {
  videoSrc: 'https://res.cloudinary.com/dn1ko8lbn/video/upload/v1723043676/Palmshops/Introduction/intro.mp4',
};

VideoPlayer.propTypes = {
  videoSrc: PropTypes.string,
};

export default VideoPlayer;
