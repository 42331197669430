/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import NavB from '../../../Nav/NavB';
import VideoPlayer from '../../components/VideoPlayer';
import styles from './css/Course.module.scss';
import Loader from '../../components/Loader';
// import courses from '../../components/data/courses';

const Course = () => {
  const { courseId } = useParams();
  const navigate = useNavigate();

  const [selectedCourse, setSelectedCourse] = useState(null);
  const [isError, setIsError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchCourse = async () => {
      const token = localStorage.getItem('token');
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      try {
        setIsLoading(true);
        if (!courseId) {
          setIsError('Course not found');
          setIsLoading(false);
          return;
        }
        const response = await fetch(`${process.env.REACT_APP_BASEURL}/courses/${courseId}`, {
          method: 'GET',
          headers,
        });

        if (!response.ok) {
          setIsError('Failed to fetch course');
          toast.error('Check your internet connection and try again');
          setIsLoading(false);
          return;
        }

        const data = await response.json();

        setSelectedCourse(data);
        setIsLoading(false);
      } catch (error) {
        setIsError(error.message);
        toast.error('Something went wrong, try again');
      } finally {
        setIsLoading(false);
      }
    };

    fetchCourse();
  }, []);

  if (isError) {
    return (
      <div>
        Error: Check your network connection and try again
      </div>
    );
  }

  if (isLoading) {
    return <Loader loading={isLoading} />;
  }

  const handleClick = (module) => {
    navigate(`/palmschool/courses/${courseId}/${module._id}`);
  };

  return (
    <>
      <NavB palmschool />
      <section className={styles.container}>
        <div className={styles.div}>
          <h1 className={styles.title}>{ selectedCourse && selectedCourse.title}</h1>
        </div>
        <VideoPlayer videoSrc={selectedCourse.link} />
        <div className={styles.modules}>
          <h3 className={styles.courseTitle}>Modules</h3>
          <div className={styles.courseDiv}>
            {selectedCourse && selectedCourse.modules.map((module) => (
              <div
                key={module._id}
                className={styles.course}
                onClick={() => handleClick(module)}
              >
                <h2 className={styles.courseId}>{module.id}</h2>
                <img src={module.img} className={styles.img} alt={module.subtopic} />
                <div className={styles.courseAction}>
                  <article className={styles.courseBrief}>
                    <h4 className={styles.courseTitle}>{module.subtopic}</h4>
                    <p className={styles.courseText}>{module.description}</p>
                  </article>
                  <article className={styles.tracker}>
                    <p className={styles.trackerTimer}>
                      {module.duration}
                      <span>mins</span>
                    </p>
                    <progress className={styles.trackerPercent} value={module.progress || 10} max={module.duration} />
                  </article>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Course;
