/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import BounceLoader from 'react-spinners/BounceLoader';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import styles from './css/Loader.module.scss';

const Loader = ({ loading }) => (
  <motion.div
    className={styles.loader}
    initial={{ y: -100, width: 0 }}
    animate={{ y: 0, width: '100%' }}
    exit={{ x: window.innerWidth, transition: { duration: 0.2 } }}
  >
    <BounceLoader color="#7ec670" loading={loading} size={80} />
  </motion.div>
);

Loader.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default Loader;
