/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useRef, useEffect } from 'react';
import { useFormik } from 'formik';
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';
import { MdImage } from 'react-icons/md';
import { motion } from 'framer-motion';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import Success from '../../components/Success';
import Loader from '../../../PalmSchool/components/Loader';
import states from '../../../PalmTrack/assets/states.json';
import styles from './Form.module.scss';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const validationSchema = Yup.object().shape({
  type: Yup.string().required('Required'),
  cropPlanted: Yup.string().required('Required'),
  farmSize: Yup.number().required('Required'),
  farmSizeUnit: Yup.string().required('Required'),
  description: Yup.string(),
  address: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  lga: Yup.string().required('Required'),
  productImage: Yup.mixed().required('Required'),
  geolocation: Yup.object().shape({
    latitude: Yup.number().required('Required'),
    longitude: Yup.number().required('Required'),
  }).nullable(),
  salesPrice: Yup.number(),
  leasePrice: Yup.number(),
  duration: Yup.string(),
});

const Farm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [arrowToggle, setArrowToggle] = useState({
    equipment: false,
    state: false,
    lga: false,
    duration: false,
  });

  const formik = useFormik({
    initialValues: {
      type: '',
      cropPlanted: '',
      farmSize: '',
      farmSizeUnit: '',
      description: '',
      productImage: null,
      geolocation: null,
      address: '',
      state: '',
      lga: '',
      salesPrice: '',
      leasePrice: '',
      duration: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      const data = await fetch(`${process.env.REACT_APP_BASEURL}/trackfarm/new`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(values),
      });
      const response = await data.json();
      if (response) {
        setIsLoading(false);
        setSuccess(true);
        formik.resetForm();
      } else {
        setIsLoading(false);
        toast.error('An error occurred, please try again');
      }
    },
  });

  const convertToBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const handleDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const base64Image = await convertToBase64(file);
    formik.setFieldValue('productImage', base64Image);
  };

  const handleArrowToggle = (field) => {
    setArrowToggle((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const geocoderContainerRef = useRef(null);
  const geocoderRef = useRef(null);
  const mapContainerRef = useRef(null);
  const map = useRef(null);

  const captureGeolocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      formik.setFieldValue('geolocation', { longitude, latitude });
      if (map.current) {
        map.current.flyTo({ center: [longitude, latitude], zoom: 16 });
      }
    }, (error) => console.error('Error getting geolocation:', error));
  };

  useEffect(() => {
    if (mapContainerRef.current && !map.current) {
      map.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [6, 5],
        zoom: 16,
        attributionControl: false,
      })
        .addControl(new mapboxgl.FullscreenControl(), 'top-right')
        .addControl(new mapboxgl.ScaleControl(), 'bottom-right')
        .addControl(new mapboxgl.GeolocateControl(), 'top-right');
    }
    return () => {
      if (map.current) {
        map.current.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (geocoderContainerRef.current && !geocoderRef.current) {
      geocoderRef.current = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl,
        placeholder: 'Enter address',
        types: 'address, country, region, place, postcode, locality, neighborhood',
      });

      geocoderRef.current.addTo(geocoderContainerRef.current);

      geocoderRef.current.on('result', (event) => {
        const { result } = event;
        formik.setFieldValue('address', result.place_name);
      });
    }
  }, []);

  return (
    <>
      <motion.form
        initial={{ opacity: 0, x: '-100%' }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: '100%' }}
        transition={{ delay: 0.5 }}
        onSubmit={formik.handleSubmit}
        className={styles.container}
      >
        <p className={styles.label}>Select as appropriate</p>
        <div className={styles.formGroupB}>
          <div className={styles.formCombo}>
            <input
              type="radio"
              name="type"
              onChange={formik.handleChange}
              value="sale"
            />
            <label htmlFor="sale">For Sale</label>
          </div>
          <div className={styles.formCombo}>
            <input
              type="radio"
              name="type"
              onChange={formik.handleChange}
              value="lease"
            />
            <label htmlFor="lease">For Lease</label>
          </div>
          {formik.errors.type && formik.touched.type && <div className={styles.error}>{formik.errors.type}</div>}
        </div>
        <div className={styles.formGroup}>
          <select
            name="cropPlanted"
            onChange={formik.handleChange}
            onClick={() => handleArrowToggle('cropPlanted')}
            value={formik.values.cropPlanted}
          >
            <option value="" label="Select type of crop planted" />
            <option value="maize" label="Maize" />
            <option value="oil_palm" label="Oil Palm" />
            <option value="cassava" label="Cassava" />
            <option value="cucumber" label="Cucumber" />
            <option value="none" label="None" />
          </select>
          {!arrowToggle.cropPlanted ? <RiArrowDownSFill className={styles.icon} /> : <RiArrowUpSFill className={styles.icon} />}
          {formik.errors.cropPlanted && formik.touched.cropPlanted && <div className={styles.error}>{formik.errors.cropPlanted}</div>}
        </div>

        <div className={styles.formSplitB}>
          <div className={styles.formGroup}>
            <input
              type="number"
              name="farmSize"
              onChange={formik.handleChange}
              placeholder="Farm Size"
              value={formik.values.farmSize}
            />
            {formik.errors.farmSize && formik.touched.farmSize && <div className={styles.error}>{formik.errors.farmSize}</div>}
          </div>
          <div className={styles.formGroup}>
            <select
              name="farmSizeUnit"
              onChange={formik.handleChange}
              onClick={() => handleArrowToggle('farmSizeUnit')}
              value={formik.values.farmSizeUnit}
            >
              <option value="" label="Unit" />
              <option value="sqm" label="SqM" />
              <option value="ha" label="Ha" />
              <option value="acres" label="Acres" />
            </select>
            {!arrowToggle.farmSizeUnit ? <RiArrowDownSFill className={styles.icon} /> : <RiArrowUpSFill className={styles.icon} />}
            {formik.errors.farmSizeUnit && formik.touched.farmSizeUnit && <div className={styles.error}>{formik.errors.farmSizeUnit}</div>}
          </div>
        </div>

        <div className={styles.formGroup}>
          <input
            type="text"
            name="description"
            onChange={formik.handleChange}
            placeholder="Brief description"
            value={formik.values.description}
          />
          {formik.errors.description && formik.touched.description && <div className={styles.error}>{formik.errors.description}</div>}
        </div>

        <div className={styles.formGroup}>
          <Dropzone onDrop={handleDrop}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className={styles.dropzone}>
                <input {...getInputProps()} />
                {formik.values.productImage ? (
                  <div className={styles.image}>
                    <img src={formik.values.productImage} alt="product" />
                    <p>Uploaded Image</p>
                  </div>
                ) : (
                  <div className={styles.image}>
                    <MdImage className={styles.img} />
                    <p>Upload Product Image</p>
                  </div>
                )}
              </div>
            )}
          </Dropzone>
          {formik.errors.productImage && formik.touched.productImage && <div className={styles.error}>{formik.errors.productImage}</div>}
        </div>

        <div className={styles.mapContainer} ref={mapContainerRef} />
        <button
          type="button"
          className={styles.reCaptureButtonB}
          onClick={captureGeolocation}
        >
          Get Geolocation
        </button>

        <div className={`${styles.formGroup} ${styles.farmDiv}`}>
          <label htmlFor="address">Location Details</label>
          <div ref={geocoderContainerRef} className={styles.geocoderContainer} />
          {formik.errors.address && formik.touched.address && <div className={styles.error}>{formik.errors.address}</div>}
        </div>
        <div className={styles.formSplit}>
          <div className={styles.formGroup}>
            <select
              name="state"
              onChange={formik.handleChange}
              onClick={() => handleArrowToggle('state')}
              value={formik.values.state}
            >
              <option value="" label="State" />
              {states.map((state) => <option key={state.state} value={state.state} label={state.state} />)}
            </select>
            {!arrowToggle.state ? <RiArrowDownSFill className={styles.icon} /> : <RiArrowUpSFill className={styles.icon} />}
            {formik.errors.state && formik.touched.state && <div className={styles.error}>{formik.errors.state}</div>}
          </div>

          <div className={styles.formGroup}>
            <select
              name="lga"
              onChange={formik.handleChange}
              onClick={() => handleArrowToggle('lga')}
              value={formik.values.lga}
            >
              <option value="" label="LGA" />
              {states.map((state) => (state.state === formik.values.state) && state.lgas.map((lga) => <option key={lga} value={lga} label={lga} />))}
            </select>
            {!arrowToggle.lga ? <RiArrowDownSFill className={styles.icon} /> : <RiArrowUpSFill className={styles.icon} />}
            {formik.errors.lga && formik.touched.lga && <div className={styles.error}>{formik.errors.lga}</div>}
          </div>
        </div>
        <div className={styles.formGroup}>
          <input
            type="text"
            name="salesPrice"
            onChange={formik.handleChange}
            placeholder="Sales Price"
            value={formik.values.salesPrice}
          />
          {formik.errors.salesPrice && formik.touchedsalesPrice && <div className={styles.error}>{formik.errors.salesPrice}</div>}
        </div>
        <div className={styles.formSplitB}>
          <div className={styles.formGroup}>
            <input
              type="text"
              name="leasePrice"
              onChange={formik.handleChange}
              placeholder="Lease Price"
              value={formik.values.leasePrice}
            />
            {formik.errors.leasePrice && formik.touched.leasePrice && <div className={styles.error}>{formik.errors.leasePrice}</div>}
          </div>
          <div className={styles.formGroup}>
            <select
              name="duration"
              onChange={formik.handleChange}
              onClick={() => handleArrowToggle('duration')}
              value={formik.values.duration}
            >
              <option value="" label="Duration" />
              <option value="sqm" label="Daily" />
              <option value="ha" label="Weekly" />
              <option value="acres" label="monthly" />
              <option value="acres" label="Yearly" />
            </select>
            {!arrowToggle.duration ? <RiArrowDownSFill className={styles.icon} /> : <RiArrowUpSFill className={styles.icon} />}
            {formik.errors.duration && formik.touched.duration && <div className={styles.error}>{formik.errors.duration}</div>}
          </div>
        </div>

        <button type="submit" className={styles.button}>Submit</button>
      </motion.form>
      {isLoading && <Loader />}
      {success && <Success />}
    </>
  );
};

export default Farm;
