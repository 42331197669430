/* eslint-disable import/extensions */
/* eslint-disable arrow-body-style */
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Home from '../Home/Home';
import WelcomePage from '../PalmSchool/pages/Courses/WelcomePage';
import Course from '../PalmSchool/pages/Courses/Course';
import NextModule from '../PalmSchool/pages/Courses/NextModule';
import Assessment from '../PalmSchool/pages/Assessment/Assessment';
import BackToCourse from '../PalmSchool/pages/Assessment/BackToCourse';
import PalmTrack from '../PalmTrack/pages/Home/PalmTrack';
import Stock from '../PalmTrack/pages/Stock/Stock';
import Login from '../PalmSchool/pages/Login';
import PalmStore from '../PalmStore/pages/PalmStore';
import Error from '../Error';

const Auth = () => {
  return (
    <AnimatePresence>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/palmSchool" element={<WelcomePage />} />
        <Route path="/palmschool/courses/:courseId" element={<Course />} />
        <Route path="/palmschool/courses/:courseId/:moduleId" element={<NextModule />} />
        <Route path="/palmschool/courses/:courseId/:moduelId/assessment/" element={<Assessment />} />
        <Route path="/palmschool/feedback" element={<BackToCourse />} />
        <Route path="/palmTrack" element={<PalmTrack />} />
        <Route path="/palmTrack/mystock" element={<Stock />} />
        <Route path="/palmStore" element={<PalmStore />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </AnimatePresence>
  );
};

export default Auth;
