/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/extensions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import mapboxgl from 'mapbox-gl';
import { motion } from 'framer-motion';
import Dropzone from 'react-dropzone';
// import MapGL, { Marker } from 'react-map-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { toast } from 'react-toastify';
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';
import PropTypes from 'prop-types';
import states from '../../assets/states.json';
import NavB from '../../../Nav/NavB';
import styles from './ViewStock.module.scss';
import { updateFarmStock } from '../../../redux/reducer/farmAction';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const validationSchema = Yup.object({
  productImage: Yup.mixed().required('Required'),
  expectedYield: Yup.number().required('Required'),
  pricePerUnit: Yup.string().required('Required'),
  address: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  lga: Yup.string().required('Required'),
  geolocation: Yup.object().shape({
    latitude: Yup.number().required('Required'),
    longitude: Yup.number().required('Required'),
  }),
});

const EditFarmStockDetails = ({ store }) => {
  const dispatch = useDispatch();
  const [arrowToggle, setArrowToggle] = useState({
    state: false,
    lga: false,
  });

  const handleArrowToggle = (field) => {
    setArrowToggle((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const formik = useFormik({
    initialValues: {
      productImage: store.productImage,
      expectedYield: store.expectedYield,
      pricePerUnit: store.pricePerUnit,
      address: store.address,
      state: store.state,
      lga: store.lga,
      geolocation: {
        latitude: store.geolocation.coordinates[1],
        longitude: store.geolocation.coordinates[0],
      },
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(updateFarmStock({ id: store._id, update: values }));
      toast.success('Stock details updated successfully!');
    },
  });

  const geocoderContainerRef = useRef(null);
  const geocoderRef = useRef(null);
  const mapContainerRef = useRef(null);
  const map = useRef(null);

  const handleRecaptureGeolocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      formik.setFieldValue('geolocation', { longitude, latitude });
      if (map.current) {
        map.current.flyTo({ center: [longitude, latitude], zoom: 12 });
      }
    }, (error) => console.error('Error getting geolocation:', error));
  };

  useEffect(() => {
    if (mapContainerRef.current) {
      map.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [store.geolocation.coordinates[0], store.geolocation.coordinates[1]],
        zoom: 12,
        attributionControl: false,
      })
        .addControl(new mapboxgl.FullscreenControl(), 'top-right')
        .addControl(new mapboxgl.ScaleControl(), 'bottom-right')
        .addControl(new mapboxgl.GeolocateControl(), 'top-right');
    }
    return () => {
      if (map.current) {
        map.current.remove();
      }
    };
  }, [store.geolocation.coordinates]);

  useEffect(() => {
    if (geocoderContainerRef.current && !geocoderRef.current) {
      geocoderRef.current = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl,
        placeholder: formik.values.address,
        types: 'address, country, region, place, postcode, locality, neighborhood',
      });

      geocoderRef.current.addTo(geocoderContainerRef.current);

      geocoderRef.current.on('result', (event) => {
        const { result } = event;
        formik.setFieldValue('address', result.place_name);
      });
    }
  }, []);

  const convertToBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const handleDrop = async (acceptedFiles, fileRejections) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const base64Image = await convertToBase64(file);
      formik.setFieldValue('productImage', base64Image);
    } else if (fileRejections.length > 0) {
      fileRejections.forEach((file) => {
        const { file: rejectedFile, errors } = file;
        errors.forEach((error) => {
          if (error.code === 'file-too-large') {
            toast.error(`File "${rejectedFile.name}" is too large. Maximum size is 500KB.`);
          }
        });
      });
    }
  };

  return (
    <>
      <NavB palmtrack />
      <motion.div
        initial={{ opacity: 0, x: '-100%' }}
        whileInView={{ opacity: 1, x: 0 }}
        className={styles.editStockDetail}
      >
        <form onSubmit={formik.handleSubmit} className={styles.form}>
          <div className={styles.formGroup}>
            <Dropzone onDrop={handleDrop} maxSize={512000}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className={styles.dropzone}>
                  <input {...getInputProps()} />
                  {formik.values.productImage ? (
                    <div className={styles.image}>
                      <img src={formik.values.productImage} alt="product" />
                      <h3 className={styles.productName}>{store.cropPlanted}</h3>
                      <button type="button" className={styles.changePicture}>
                        Change Picture (500kb max)
                      </button>
                    </div>
                  ) : (
                    <div className={styles.image}>
                      <p>Upload Image (500kb max)</p>
                    </div>
                  )}
                </div>
              )}
            </Dropzone>
            {formik.errors.productImage && formik.touched.productImage && <div className={styles.error}>{formik.errors.productImage}</div>}
          </div>
          <div className={styles.splitter}>
            <div className={styles.formGroup}>
              <label htmlFor="expectedYield">Quantity</label>
              <input
                name="expectedYield"
                type="number"
                onChange={formik.handleChange}
                value={formik.values.expectedYield}
                className={formik.errors.expectedYield ? styles.error : ''}
              />
              {formik.errors.expectedYield && <div className={styles.errorMessage}>{formik.errors.expectedYield}</div>}
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="pricePerUnit">Price</label>
              <input
                name="pricePerUnit"
                type="number"
                onChange={formik.handleChange}
                value={formik.values.pricePerUnit}
                className={formik.errors.pricePerUnit ? styles.error : ''}
              />
              {formik.errors.pricePerUnit && <div className={styles.errorMessage}>{formik.errors.pricePerUnit}</div>}
            </div>
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="address">Location of Product</label>
            <div ref={geocoderContainerRef} className={styles.geocoderContainer} />
            {formik.errors.address && formik.touched.address && <div className={styles.error}>{formik.errors.address}</div>}
          </div>

          <div className={styles.splitter}>
            <div className={styles.formGroup}>
              <select
                name="state"
                onChange={formik.handleChange}
                onClick={() => handleArrowToggle('state')}
                value={formik.values.state}
              >
                <option value="" label="State" />
                {states.map((state) => <option key={state.state} value={state.state} label={state.state} />)}
              </select>
              {!arrowToggle.state ? <RiArrowDownSFill className={styles.icon} /> : <RiArrowUpSFill className={styles.icon} />}
              {formik.errors.state && formik.touched.state && <div className={styles.error}>{formik.errors.state}</div>}
            </div>

            <div className={styles.formGroup}>
              <select
                name="lga"
                onChange={formik.handleChange}
                onClick={() => handleArrowToggle('lga')}
                value={formik.values.lga}
              >
                <option value="" label="LGA" />
                {states.map((state) => (state.state === formik.values.state) && state.lgas.map((lga) => <option key={lga} value={lga} label={lga} />))}
              </select>
              {!arrowToggle.lga ? <RiArrowDownSFill className={styles.icon} /> : <RiArrowUpSFill className={styles.icon} />}
              {formik.errors.lga && formik.touched.lga && <div className={styles.error}>{formik.errors.lga}</div>}
            </div>
          </div>
          <div className={styles.mapContainer} ref={mapContainerRef} />
          <button
            type="button"
            className={styles.reCaptureButton}
            onClick={handleRecaptureGeolocation}
          >
            Re-Capture Geolocation
          </button>

          <button type="submit" className={styles.updateButton}>Update</button>
        </form>
      </motion.div>
    </>
  );
};

EditFarmStockDetails.propTypes = {
  store: PropTypes.object.isRequired,
};

export default EditFarmStockDetails;
