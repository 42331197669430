/* eslint-disable arrow-body-style */
import React from 'react';
import { useSelector } from 'react-redux';
import Nav from '../Nav/Nav';
import styles from './Home.module.scss';
import HeroPage from '../PalmSchool/components/HeroPage';
import Palm from '../PalmSchool/components/Palm';
import Subscribe from '../PalmSchool/components/Subscribe';
import Footer from '../PalmSchool/components/Footer';
// import auth from '../auth';

const Home = () => {
  const auth = useSelector((state) => state.auth.isAuthenticated);

  window.scrollTo(0, 0);

  return (
    <div className={styles.container}>
      <Nav auth={auth} />
      <HeroPage auth={auth} />
      <Palm />
      <Subscribe />
      <Footer />
    </div>
  );
};

export default Home;
