/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useRef, useEffect } from 'react';
import { useFormik } from 'formik';
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';
import * as Yup from 'yup';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import states from '../../../PalmTrack/assets/states.json';
import Success from '../../components/Success';
import Loader from '../../../PalmSchool/components/Loader';
import styles from './Form.module.scss';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const validationSchema = Yup.object().shape({
  type: Yup.string().required('Required'),
  equipment: Yup.string().required('Required'),
  description: Yup.string(),
  address: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  lga: Yup.string().required('Required'),
  salesPrice: Yup.number(),
  leasePrice: Yup.number(),
  duration: Yup.string(),
});

const Equipment = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [arrowToggle, setArrowToggle] = useState({
    equipment: false,
    state: false,
    lga: false,
    duration: false,
  });

  const formik = useFormik({
    initialValues: {
      type: '',
      equipment: '',
      description: '',
      address: '',
      state: '',
      lga: '',
      salesPrice: '',
      leasePrice: '',
      duration: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      const data = await fetch(`${process.env.REACT_APP_BASEURL}/trackequipment/new`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(values),
      });
      const response = await data.json();
      if (response) {
        setIsLoading(false);
        setSuccess(true);
        formik.resetForm();
      } else {
        setIsLoading(false);
        toast.error('An error occurred, please try again');
      }
    },
  });

  const handleArrowToggle = (field) => {
    setArrowToggle((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const geocoderContainerRef = useRef(null);
  const geocoderRef = useRef(null);

  useEffect(() => {
    if (geocoderContainerRef.current && !geocoderRef.current) {
      geocoderRef.current = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl,
        placeholder: 'Enter address',
        types: 'address, country, region, place, postcode, locality, neighborhood',
      });

      geocoderRef.current.addTo(geocoderContainerRef.current);

      geocoderRef.current.on('result', (event) => {
        const { result } = event;
        formik.setFieldValue('address', result.place_name);
      });
    }
  }, []);

  return (
    <>
      <motion.form
        initial={{ opacity: 0, x: '-100%' }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: '100%' }}
        transition={{ delay: 0.5 }}
        onSubmit={formik.handleSubmit}
        className={styles.container}
      >
        <p className={styles.label}>Select as appropriate</p>
        <div className={styles.formGroupB}>
          <div className={styles.formCombo}>
            <input
              type="radio"
              name="type"
              onChange={formik.handleChange}
              value="sale"
            />
            <label htmlFor="sale">For Sale</label>
          </div>
          <div className={styles.formCombo}>
            <input
              type="radio"
              name="type"
              onChange={formik.handleChange}
              value="lease"
            />
            <label htmlFor="lease">For Lease</label>
          </div>
          {formik.errors.type && formik.touched.type && <div className={styles.error}>{formik.errors.type}</div>}
        </div>
        <div className={styles.formGroup}>
          <select
            name="equipment"
            onChange={formik.handleChange}
            onClick={() => handleArrowToggle('equipment')}
            value={formik.values.equipment}
          >
            <option value="" label="Type of equipment" />
            <option value="tractor" label="Tractor" />
            <option value="sprayer" label="Sprayer" />
            <option value="disc_plough" label="Disc Plough" />
            <option value="lorry" label="Lorry" />
            <option value="oil_mill" label="Oil Mill" />
          </select>
          {!arrowToggle.equipment ? <RiArrowDownSFill className={styles.icon} /> : <RiArrowUpSFill className={styles.icon} />}
          {formik.errors.equipment && formik.touched.equipment && <div className={styles.error}>{formik.errors.equipment}</div>}
        </div>

        <div className={styles.formGroup}>
          <input
            type="text"
            name="description"
            onChange={formik.handleChange}
            placeholder="Brief description"
            value={formik.values.description}
          />
          {formik.errors.description && formik.touched.description && <div className={styles.error}>{formik.errors.description}</div>}
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="address">Location</label>
          <div ref={geocoderContainerRef} className={styles.geocoderContainer} />
          {formik.errors.address && formik.touched.address && <div className={styles.error}>{formik.errors.address}</div>}
        </div>
        <div className={styles.formSplit}>
          <div className={styles.formGroup}>
            <select
              name="state"
              onChange={formik.handleChange}
              onClick={() => handleArrowToggle('state')}
              value={formik.values.state}
            >
              <option value="" label="State" />
              {states.map((state) => <option key={state.state} value={state.state} label={state.state} />)}
            </select>
            {!arrowToggle.state ? <RiArrowDownSFill className={styles.icon} /> : <RiArrowUpSFill className={styles.icon} />}
            {formik.errors.state && formik.touched.state && <div className={styles.error}>{formik.errors.state}</div>}
          </div>

          <div className={styles.formGroup}>
            <select
              name="lga"
              onChange={formik.handleChange}
              onClick={() => handleArrowToggle('lga')}
              value={formik.values.lga}
            >
              <option value="" label="LGA" />
              {states.map((state) => (state.state === formik.values.state) && state.lgas.map((lga) => <option key={lga} value={lga} label={lga} />))}
            </select>
            {!arrowToggle.lga ? <RiArrowDownSFill className={styles.icon} /> : <RiArrowUpSFill className={styles.icon} />}
            {formik.errors.lga && formik.touched.lga && <div className={styles.error}>{formik.errors.lga}</div>}
          </div>
        </div>
        <div className={styles.formGroup}>
          <input
            type="text"
            name="salesPrice"
            onChange={formik.handleChange}
            placeholder="Sales Price"
            value={formik.values.salesPrice}
          />
          {formik.errors.salesPrice && formik.touchedsalesPrice && <div className={styles.error}>{formik.errors.salesPrice}</div>}
        </div>
        <div className={styles.formSplitB}>
          <div className={styles.formGroup}>
            <input
              type="text"
              name="leasePrice"
              onChange={formik.handleChange}
              placeholder="Lease Price"
              value={formik.values.leasePrice}
            />
            {formik.errors.leasePrice && formik.touched.leasePrice && <div className={styles.error}>{formik.errors.leasePrice}</div>}
          </div>
          <div className={styles.formGroup}>
            <select
              name="duration"
              onChange={formik.handleChange}
              onClick={() => handleArrowToggle('duration')}
              value={formik.values.duration}
            >
              <option value="" label="Duration" />
              <option value="sqm" label="Daily" />
              <option value="ha" label="Weekly" />
              <option value="acres" label="monthly" />
              <option value="acres" label="Yearly" />
            </select>
            {!arrowToggle.duration ? <RiArrowDownSFill className={styles.icon} /> : <RiArrowUpSFill className={styles.icon} />}
            {formik.errors.duration && formik.touched.duration && <div className={styles.error}>{formik.errors.duration}</div>}
          </div>
        </div>

        <button type="submit" className={styles.button}>Submit</button>
      </motion.form>
      {isLoading && <Loader />}
      {success && <Success />}
    </>
  );
};

export default Equipment;
