/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FaCirclePlus } from 'react-icons/fa6';
import { motion } from 'framer-motion';
import NavB from '../../../Nav/NavB';
import hero from '../../assets/hero.png';
import styles from './Home.module.scss';
import css from '../Stock/Stock.module.scss';
import RenderForm from '../../components/RenderForm';
import { getHarvestStock } from '../../../redux/reducer/harvestAction';
import { getFarmStock } from '../../../redux/reducer/farmAction';
import Loader from '../../../Home/Loader';

const animateBook = {
  isHidden: { opacity: 0, x: '-100%' },
  isVisible: (transit) => ({
    opacity: 1,
    x: 0,
    transition: { delay: transit },
  }),
};

const PalmTrack = () => {
  const dispatch = useDispatch();
  const [openForm, setOpenForm] = useState(false);
  const { loading, error, harvested } = useSelector((state) => state.harvest);
  const { farms } = useSelector((state) => state.farm);

  const store = [];
  if (harvested.length > 0) {
    harvested.forEach((product) => {
      store.push(product);
    });
  }
  if (farms.length > 0) {
    farms.forEach((product) => {
      store.push(product);
    });
  }

  useEffect(() => {
    dispatch(getHarvestStock());
    dispatch(getFarmStock());
  }, []);

  const handleOpenForm = () => {
    setOpenForm(true);
  };

  return (
    <>
      <NavB palmtrack />
      <div className={styles.hero}>
        <img src={hero} alt="hero" className={styles.img} />
        <p className={styles.heroText}>Take stock of your farm produce with PALMTRACK</p>
        {!openForm && (
          <section className={styles.heroStore}>
            <article className={styles.heroStoreItem}>
              <p className={styles.text}>{((store && store.length) === 0) ? 'Your store is empty' : `You have ${store ? store.length : 0} items`}</p>
              <button type="button" className={styles.action} onClick={handleOpenForm}>
                <svg width="0" height="0">
                  <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" style={{ stopColor: '#7ec670', stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: '#dcc15f', stopOpacity: 1 }} />
                  </linearGradient>
                </svg>
                <FaCirclePlus className={styles.icon} style={{ fill: 'url(#grad1)' }} />
                Add Product
              </button>
            </article>
          </section>
        )}
        {error && <p style={{ marginTop: '2rem' }}>Got new product to add?</p>}
        {(store && !openForm) && (
          <section className={css.store}>
            {store.map((stock, index) => (
              <motion.article
                key={stock._id}
                variants={animateBook}
                initial="isHidden"
                animate="isVisible"
                exit="isHidden"
                layoutId={index}
                transit={(index + 1) * 0.2}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 1.05 }}
                className={css.storeCard}
              >
                <img
                  src={stock.productImage}
                  alt={stock.productImage}
                  className={css.img}
                />
                <div className={css.productInfo}>
                  {stock.cropPlanted && <h3 className={css.name}>{stock.cropPlanted}</h3>}
                  {stock.productName && <h3 className={css.name}>{stock.productName}</h3>}
                  <p className={css.unitPrice}>
                    <span>N</span>
                    {stock.pricePerUnit}
                  </p>
                  {stock.expectedYield && (
                    <p className={css.qty}>
                      <span>Qty: </span>
                      {stock.expectedYield}
                    </p>
                  )}
                  {stock.availableQty && (
                    <p className={css.qty}>
                      <span>Qty: </span>
                      {stock.availableQty}
                    </p>
                  )}
                  <p className={css.location}>
                    <span>Location: </span>
                    {stock.address}
                  </p>
                </div>
              </motion.article>
            ))}
          </section>
        )}
        {openForm && (<RenderForm />)}
      </div>
      {loading && <Loader loading={loading} />}
    </>
  );
};

export default PalmTrack;
