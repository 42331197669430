/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable import/no-extraneous-dependencies */
import React, {
  useState, useEffect,
} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { AiFillCloseCircle } from 'react-icons/ai';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { MdMenu } from 'react-icons/md';
import { IoIosNotificationsOutline } from 'react-icons/io';
import { FaRegUserCircle } from 'react-icons/fa';
import { motion } from 'framer-motion';
import styles from './Nav.module.scss';
import palmshops from '../PalmSchool/images/logo/logo_text.svg';
import { signOut } from '../redux/reducer/authActions';
import Loader from '../Home/Loader';
/* eslint-disable react/jsx-boolean-value */

// eslint-disable-next-line react/prop-types
const Nav = ({ auth, notif }) => {
  useEffect(() => {
    AOS.init({
      duration: 400,
      easing: 'ease-in',
      once: true,
    });
  }, []);

  const dispatch = useDispatch();
  const { loading, success } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const [isShowing, setIsShowing] = useState(false);

  const handleMenuOpen = () => {
    setToggle(!toggle);
  };

  const handleMenuClose = () => {
    setToggle(false);
  };

  const showMenu = () => {
    setIsShowing(!isShowing);
  };

  const handleSignOut = () => {
    dispatch(signOut());
    if (success) {
      navigate('/');
    }
  };

  return (
    <header className={styles.scroller}>
      <motion.nav
        initial={{ x: -100 }}
        animate={{ x: 0 }}
        transition={{ type: 'spring', stiffness: 120 }}
        className={styles.navBar}
      >
        <div className={styles.navPre}>
          {
          toggle ? (
            <AiFillCloseCircle onClick={handleMenuClose} className={styles.exitMenu} />
          ) : (
            <MdMenu onClick={handleMenuOpen} className={styles.mobileMenuIcon} />
          )
        }

          <Link to="/" className={styles.navLink}>
            <img
              src={palmshops}
              alt="PalmShops.com logo"
              className={styles.navLogo}
            />
          </Link>
        </div>
        {toggle && (
          <div className={styles.navLinksMob}>
            <motion.ul className={styles.navList}>
              <motion.li
                className={styles.navListItem}
                whileHover={{ scale: 1.05, originX: 0 }}
                whileTap={{ scale: 0.95 }}
              >
                <Link
                  to="/"
                  className={styles.navLinkItem}
                  onClick={handleMenuClose}
                >
                  Home
                </Link>
              </motion.li>
              <motion.li
                className={styles.navListItem}
                whileHover={{ scale: 1.05, originX: 0 }}
                whileTap={{ scale: 0.95 }}
              >
                <Link to="palmSchool" className={styles.navLinkItem} data-aos="fade-up" onClick={handleMenuClose}>
                  Palm School
                </Link>
              </motion.li>
              <motion.li
                className={styles.navListItem}
                whileHover={{ scale: 1.05, originX: 0 }}
                whileTap={{ scale: 0.95 }}
              >
                <Link to="palmTrack" className={styles.navLinkItem} data-aos="fade-up" onClick={handleMenuClose}>
                  PalmTrack
                </Link>
              </motion.li>
              <motion.li
                className={styles.navListItem}
                whileHover={{ scale: 1.05, originX: 0 }}
                whileTap={{ scale: 0.95 }}
              >
                <Link to="palmStore" className={styles.navLinkItem} data-aos="fade-up" onClick={handleMenuClose}>
                  Palm Store
                </Link>
              </motion.li>
              <motion.li
                className={styles.navListItem}
                whileHover={{ scale: 1.05, originX: 0 }}
                whileTap={{ scale: 0.95 }}
              >
                <Link to="palmVest" className={styles.navLinkItem} data-aos="fade-up" onClick={handleMenuClose}>
                  PalmVest
                </Link>
              </motion.li>
            </motion.ul>
          </div>
        )}
        <div className={styles.navLinks}>
          <ul className={styles.navList}>
            <li className={styles.navListItem}>
              <Link to="/" className={styles.navLinkItem} data-aos="fade-up" onClick={handleMenuClose}>
                Home
              </Link>
            </li>
            <li className={styles.navListItem}>
              <Link to="palmSchool" className={styles.navLinkItem} data-aos="fade-up" onClick={handleMenuClose}>
                Palm School
              </Link>
            </li>
            <li className={styles.navListItem}>
              <Link to="palmTrack" className={styles.navLinkItem} data-aos="fade-up" onClick={handleMenuClose}>
                PalmTrack
              </Link>
            </li>
            <li className={styles.navListItem}>
              <Link to="palmStore" className={styles.navLinkItem} data-aos="fade-up" onClick={handleMenuClose}>
                Palm Store
              </Link>
            </li>
            <li className={styles.navListItem}>
              <Link to="palmVest" className={styles.navLinkItem} data-aos="fade-up" onClick={handleMenuClose}>
                PalmVest
              </Link>
            </li>
          </ul>
        </div>
        <div className={styles.profile}>
          {auth ? (
            <>
              <IoIosNotificationsOutline className={styles.icon} />
              {(notif && notif.map((no) => !no.isRead)) && <span className={styles.notif} />}
              <Link to="#" className={styles.profileImg} onClick={showMenu}>
                <FaRegUserCircle className={styles.user} />
              </Link>
              { isShowing && (
                <article className={styles.action}>
                  <button type="button" onClick={handleSignOut} className={styles.logout}>
                    Log out
                  </button>
                </article>
              )}
            </>
          ) : (
            <Link to="/register" className={styles.signup}>
              Get Started
            </Link>
          )}
        </div>
        {loading && <Loader loading={loading} />}
      </motion.nav>

      <Outlet />
    </header>
  );
};

Nav.defaultProps = {
  notif: false,
};

Nav.propTypes = {
  auth: PropTypes.bool.isRequired,
  notif: PropTypes.bool,
};

export default Nav;
