/* eslint-disable import/extensions */
/* eslint-disable arrow-body-style */
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Home from '../Home/Home';
import SignUp from '../PalmSchool/pages/SignUp';
import Confirm from '../PalmSchool/pages/Confirm';
import Login from '../PalmSchool/pages/Login';
// import Error from '../Error';

const UnAuth = () => {
  return (
    <AnimatePresence>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<SignUp />} />
        <Route path="/confirm-phone" element={<Confirm />} />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Login />} />
      </Routes>
    </AnimatePresence>
  );
};

export default UnAuth;
