import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FiCheckCircle } from 'react-icons/fi';
import styles from './css/Success.module.scss';

const Success = () => {
  const [success, setSuetIccess] = useState(false);
  const hideForm = () => {
    setSuetIccess(true);
  };

  return (
    <div className={`${styles.container} ${success ? styles.hide : ''}`}>
      <div className={styles.success}>
        <FiCheckCircle className={styles.icon} />
        <h3 className={styles.title}>We got your request</h3>
        <p className={styles.text}>One of out top agents will be in touch shortly!</p>
        <Link to="/palmStore" onClick={hideForm} className={styles.link}>Continue Shopping</Link>
      </div>
    </div>
  );
};

export default Success;
