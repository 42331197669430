/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';
import * as Yup from 'yup';
import mapboxgl from 'mapbox-gl';
import { motion } from 'framer-motion';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { toast } from 'react-toastify';
import Success from '../../components/Success';
import Loader from '../../../PalmSchool/components/Loader';
import states from '../../../PalmTrack/assets/states.json';
import styles from './Form.module.scss';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const validationSchema = Yup.object().shape({
  productName: Yup.string().required('Required'),
  quantity: Yup.number().required('Only numbers are required'),
  pricePerUnit: Yup.number().required('Required'),
  address: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  lga: Yup.string().required('Required'),
  geolocation: Yup.object().shape({
    latitude: Yup.number().required('Required'),
    longitude: Yup.number().required('Required'),
  }).nullable(),
});

const Crop = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [arrowToggle, setArrowToggle] = useState({
    productName: false,
    state: false,
    lga: false,
  });

  const formik = useFormik({
    initialValues: {
      productName: '',
      quantity: '',
      pricePerUnit: '',
      address: '',
      state: '',
      lga: '',
      geolocation: null,
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      const data = await fetch(`${process.env.REACT_APP_BASEURL}/trackcrop/new`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(values),
      });
      const response = await data.json();
      if (response) {
        setIsLoading(false);
        setSuccess(true);
        formik.resetForm();
      } else {
        setIsLoading(false);
        toast.error('An error occurred, please try again');
      }
    },
  });

  const handleArrowToggle = (field) => {
    setArrowToggle((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const geocoderContainerRef = useRef(null);
  const geocoderRef = useRef(null);
  const mapContainerRef = useRef(null);
  const map = useRef(null);

  const captureGeolocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      formik.setFieldValue('geolocation', { longitude, latitude });
      if (map.current) {
        map.current.flyTo({ center: [longitude, latitude], zoom: 16 });
      }
    }, (error) => console.error('Error getting geolocation:', error));
  };

  useEffect(() => {
    if (mapContainerRef.current && !map.current) {
      map.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [6, 5],
        zoom: 16,
        attributionControl: false,
      })
        .addControl(new mapboxgl.FullscreenControl(), 'top-right')
        .addControl(new mapboxgl.ScaleControl(), 'bottom-right')
        .addControl(new mapboxgl.GeolocateControl(), 'top-right');
    }
    return () => {
      if (map.current) {
        map.current.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (geocoderContainerRef.current && !geocoderRef.current) {
      geocoderRef.current = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl,
        placeholder: 'Enter address',
        types: 'address, country, region, place, postcode, locality, neighborhood',
      });

      geocoderRef.current.addTo(geocoderContainerRef.current);

      geocoderRef.current.on('result', (event) => {
        const { result } = event;
        formik.setFieldValue('address', result.place_name);
      });
    }
  }, []);

  useEffect(() => {
    if (formik.values.geolocation && map.current) {
      const { latitude, longitude } = formik.values.geolocation;
      if (latitude && longitude) {
        map.current.flyTo({ center: [longitude, latitude], zoom: 12 });
      }
    }
  }, [formik.values.geolocation]);

  return (
    <>
      <motion.form
        initial={{ opacity: 0, x: '-100%' }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: '100%' }}
        transition={{ delay: 0.5 }}
        onSubmit={formik.handleSubmit}
        className={styles.container}
      >
        <div className={styles.formGroup}>
          <select
            name="productName"
            onChange={formik.handleChange}
            onClick={() => handleArrowToggle('productName')}
            value={formik.values.productName}
          >
            <option value="" label="Product name" />
            <option value="palm oil (25 lts)" label="Palm Oil (25 litres)" />
            <option value="palm kernel" label="Palm Kernel" />
            <option value="cocoa" label="Cocoa" />
            <option value="cassava" label="Cassava" />
            <option value="rice" label="Rice" />
          </select>
          {!arrowToggle.productName ? <RiArrowDownSFill className={styles.icon} /> : <RiArrowUpSFill className={styles.icon} />}
          {formik.errors.productName && formik.touched.productName && <div className={styles.error}>{formik.errors.productName}</div>}
        </div>

        <div className={styles.formGroup}>
          <input
            type="text"
            name="quantity"
            onChange={formik.handleChange}
            placeholder="Enter quantity"
            value={formik.values.quantity}
          />
          {formik.errors.quantity && formik.touched.quantity && <div className={styles.error}>{formik.errors.quantity}</div>}
        </div>

        <div className={styles.formGroup}>
          <input
            type="number"
            name="pricePerUnit"
            onChange={formik.handleChange}
            placeholder="Enter Unit Price"
            value={formik.values.pricePerUnit}
          />
          {formik.errors.pricePerUnit && formik.touched.pricePerUnit && <div className={styles.error}>{formik.errors.pricePerUnit}</div>}
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="address">Location of Product</label>
          <div ref={geocoderContainerRef} className={styles.geocoderContainer} />
          {formik.errors.address && formik.touched.address && <div className={styles.error}>{formik.errors.address}</div>}
        </div>

        <div className={styles.formSplit}>
          <div className={styles.formGroup}>
            <select
              name="state"
              onChange={formik.handleChange}
              onClick={() => handleArrowToggle('state')}
              value={formik.values.state}
            >
              <option value="" label="State" />
              {states.map((state) => <option key={state.state} value={state.state} label={state.state} />)}
            </select>
            {!arrowToggle.state ? <RiArrowDownSFill className={styles.icon} /> : <RiArrowUpSFill className={styles.icon} />}
            {formik.errors.state && formik.touched.state && <div className={styles.error}>{formik.errors.state}</div>}
          </div>

          <div className={styles.formGroup}>
            <select
              name="lga"
              onChange={formik.handleChange}
              onClick={() => handleArrowToggle('lga')}
              value={formik.values.lga}
            >
              <option value="" label="LGA" />
              {states.map((state) => (state.state === formik.values.state) && state.lgas.map((lga) => <option key={lga} value={lga} label={lga} />))}
            </select>
            {!arrowToggle.lga ? <RiArrowDownSFill className={styles.icon} /> : <RiArrowUpSFill className={styles.icon} />}
            {formik.errors.lga && formik.touched.lga && <div className={styles.error}>{formik.errors.lga}</div>}
          </div>
        </div>

        <div className={styles.mapContainer} ref={mapContainerRef} />
        <button
          type="button"
          className={styles.CaptureButtonB}
          onClick={captureGeolocation}
        >
          Get Geolocation
        </button>

        <button type="submit" className={`${styles.button} ${styles.big}`}>Submit</button>
      </motion.form>
      {isLoading && <Loader />}
      {success && <Success />}
    </>
  );
};

export default Crop;
