/* eslint-disable no-underscore-dangle */
/* eslint-disable arrow-body-style */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MdStar,
  MdOutlineAccessAlarms,
  MdOutlinePriceChange,
  MdOutlineSubtitles,
} from 'react-icons/md';
import { CiGlobe } from 'react-icons/ci';
import { BsBarChart } from 'react-icons/bs';
import { toast } from 'react-toastify';
import NavB from '../../../Nav/NavB';
import VideoPlayer from '../../components/VideoPlayer';
import styles from './css/WelcomePage.module.scss';
import Loader from '../../components/Loader';

const WelcomePage = () => {
  const navigate = useNavigate();
  const [allCourses, setAllCourses] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [isOpen, setIsOpen] = useState(null);

  const fetchAvailableCourses = async () => {
    const token = localStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    try {
      setIsLoading(true);
      const response = await fetch(`${process.env.REACT_APP_BASEURL}/courses`, {
        method: 'GET',
        headers,
      });

      if (!response.ok) {
        toast.error('Check your internet connection and try again');
        throw new Error('Failed to fetch doctor');
      }

      const data = await response.json();
      setAllCourses(data);
      setIsLoading(false);
    } catch (error) {
      toast.error('Something went wrong, try again');
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchAvailableCourses();
  }, []);

  const proceedToCourse = async (courseId) => {
    const token = localStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    try {
      setIsLoading(true);
      const response = await fetch(`${process.env.REACT_APP_BASEURL}/courses/${courseId}/enroll`, {
        method: 'POST',
        headers,
      });

      if (!response.ok) {
        toast.error(response.message);
      }

      const data = await response.json();
      if (data) {
        toast.success(data.message);
        navigate(`/palmschool/courses/${courseId}`);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw new Error(error.message);
    }
  };

  const exitProjectInfo = () => setIsOpen(!isOpen);

  const handleClick = (course) => {
    setIsOpen(!isOpen);
    setSelectedCourse(course);
  };

  return (
    <>
      <NavB palmschool />
      <section className={styles.container}>
        <div className={styles.div}>
          <h1 className={styles.title}>Welcome to PalmSchool</h1>
          <p className={styles.subtitle}>Watch the introductory video below!</p>
        </div>
        <VideoPlayer />
        <div className={styles.courses}>
          <h3 className={styles.courseTitle}>Available Courses</h3>
          <div className={styles.courseDiv}>
            {isLoading && <Loader loading={isLoading} />}
            {
              allCourses && allCourses.map((course) => (
                <div
                  key={course._id}
                  className={styles.course}
                  onClick={() => handleClick(course)}
                >
                  <h2 className={styles.courseId}>{course.id}</h2>
                  <img src={course.image} className={styles.img} alt={course.name} />
                  <h5 className={styles.courseName}>{course.title}</h5>
                  <p className={styles.courseStar}>
                    {Array(5).fill(0).map((_, index) => (
                      <MdStar
                        key={_._id}
                        className={index < course.rating ? styles.filledStar : styles.lightStar}
                      />
                    ))}
                  </p>
                </div>
              ))
            }
          </div>
        </div>
        {
          isOpen && (
            <div className={styles.courseDetails}>
              {
                selectedCourse && (
                  <div key={selectedCourse._id} className={styles.courseDetail}>
                    <h2 className={styles.courseDetailId}>{selectedCourse.id}</h2>
                    <img
                      src={selectedCourse.image}
                      className={styles.img}
                      alt={selectedCourse.course}
                    />
                    <h4 className={styles.courseName}>{selectedCourse.title}</h4>
                    <p className={styles.courseDesc}>{selectedCourse.description}</p>
                    <div className={styles.courseInfo}>
                      <p className={styles.courseMode}>
                        <CiGlobe className={styles.courseLabel} />
                        {selectedCourse.mode}
                      </p>
                      <p className={styles.courseMode}>
                        <BsBarChart className={styles.courseLabel} />
                        {selectedCourse.level}
                      </p>
                      <p className={styles.courseMode}>
                        <MdOutlineAccessAlarms className={styles.courseLabel} />
                        {selectedCourse.duration}
                      </p>
                      <p className={styles.courseMode}>
                        <MdOutlineSubtitles className={styles.courseLabel} />
                        English Subtitle
                      </p>
                      <p className={styles.courseMode}>
                        <MdOutlinePriceChange className={styles.courseLabel} />
                        {selectedCourse.price}
                      </p>
                    </div>
                    <div className={styles.actionDiv}>
                      <button
                        type="button"
                        className={`${styles.exit} ${styles.btn}`}
                        onClick={exitProjectInfo}
                      >
                        Back
                      </button>
                      <button
                        type="button"
                        onClick={() => proceedToCourse(selectedCourse._id)}
                        className={`${styles.continue} ${styles.btn}`}
                      >
                        Proceed
                      </button>
                    </div>
                  </div>
                )
              }
            </div>
          )
        }
      </section>
    </>
  );
};

export default WelcomePage;
